export const useNavigator = () => {
  const reqAgent = useRequestHeader('user-agent');
  const isMobile = ref(false);
  const isIOS = ref(false);
  const isAndroid = ref(false);

  let userAgent;
  if (import.meta.server && reqAgent) {
    userAgent = reqAgent;
  } else if (import.meta.client) {
    userAgent = navigator.userAgent || (window as any).opera;
  }

  onMounted(() => {
    if (import.meta.client) {
      userAgent = navigator.userAgent || (window as any).opera;
    }
  });

  // Check for mobile
  isMobile.value = /Android|webOS|iPhone|iPad|iPod|BlackBerry|IEMobile|Opera Mini/i.test(userAgent);

  // Check for iOS
  isIOS.value = /iPad|iPhone|iPod/.test(userAgent);

  // Check for Android
  isAndroid.value = /Android/i.test(userAgent);

  return {
    isMobile,
    isIOS,
    isAndroid,
  };
};
